/* eslint no-console:0 */

// Vendor
// Note jquery and popper imported via config/environment.js
import "vendor/bootstrap.bundle.min";
require("@rails/ujs").start()

// App js
import "custom/misc";
import "custom/clients";
import "controllers"
