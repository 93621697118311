import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hide"
export default class extends Controller {
  hideElement(e) {
    e.preventDefault()
    this.element.style.display = "none"
    let selector = this.element.dataset.target
    let elements = document.querySelectorAll(selector)
    elements.forEach((element) => {
      element.style.display = "none";
    })
    this.element.nextElementSibling.classList.remove("d-none")
  }
  connect() {
  }
}
