$('document').ready(function () {
  // Custom plugin to handle toggling element classes
  $('[data-custom-toggle]').on('click', function(e) {
    e.preventDefault();

    var target = $(this).data('custom-target');
    var className = 'active';

    if ($(this).data('custom-class')) {
      className = $(this).data('custom-class')
    }

    $(target).toggleClass(className);

    $(this).toggleClass(className)
      .attr('aria-expanded', $(this).hasClass(className));
  })
});
